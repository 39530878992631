import * as React from "react"
import * as styles from "./result.module.scss"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Button from "../../components/button"
import Footer from "../../components/v2/footer"
import Header from "../../components/v2/header"
import SEO from "../../components/seo"
import Section from "../../components/section"
import { graphql } from "gatsby"

const CreatorResultPage = ({ data }) => {
  return (
    <>
      <SEO
        title="Magnetic Marketing Archetype Quiz | Creator"
        description="Discover your magnetic marketing archetype and learn how to leverage it to grow your business."
        noIndex={ true }
      />
      <Header btnTheme="primary" />
      <main className={ styles.result }>
        <Section theme="dark" style={ { marginTop: `4.5rem`, overflow: `hidden` } }>
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-6">
              <h1 className="color-white"><span className="heading-xs-upper">Your Magnetic Marketing Archetype is an...</span>Creator!</h1>
              <p className="heading-sm color-white">Daydreamers of the world unite! There’s nothing you love more than getting lost in creative flow. Self-expression is one of your core values, and you have a deep well of inspiration you can pull from at a moment’s notice.</p>
              <p className="heading-sm color-white">Your overflowing creativity helps you think of innovative solutions no one else could have come up with. Like John Lennon, they may say you’re a dreamer, but you aren’t the only one. You love collaborating with others and using your talents to make a difference in the world through your voice and vision.</p>
              <p className="heading-sm color-white">Sometimes you have such an abundance of ideas that it may be hard to discern which one is “right,” but don’t worry, Creator! That just means you’re leaning into your marketing strengths as a visionary and artist.</p>
              <p className="heading-sm color-white">Our advice? Don’t let your creativity keep you from taking action on your ideas. Then you’ll be able to move out of the ideation phase and into the creation phase — your favorite!</p>
            </div>
            <div className="col-lg-5">
              <div className={ styles.quote }>
                <p className="heading-sm color-white">“Do whatever brings you to life, then. Follow your own fascinations, obsessions, and compulsions. Trust them. Create whatever causes a revolution in your heart.”<br/><br/><span style={ { color: `#dfe3ff` } }>- Elizabeth Gilbert</span></p>
                <svg width="344" height="345" viewBox="0 0 344 345" fill="#23194D" xmlns="http://www.w3.org/2000/svg" style={ { position: `absolute`, right: `-150px`, top: `-200px`, zIndex: `0` } }>
                  <ellipse cx="172" cy="172.121" rx="172" ry="172.121" fill="inherit"></ellipse>
                </svg>
              </div>
            </div>
          </div>
        </Section>
        <Section theme="grey">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8">
              <h3 className="heading-lg color-black text-center">Ready to get personal?</h3>
              <p className="heading-sm color-pink text-center"><strong>Read on for 3 exercises to better understand your archetype!</strong></p>
              <p className="heading-sm color-blue text-center">As a Creator, you may struggle with carving a clear path that encourages your audience to buy from you. We want to help you make more sales without the hassle and hustle of traditional marketing. Your strategies should be as innovative as you are! Here’s how to get started...</p>
              <div className={ styles.list }>
                <div className={ styles.item }>
                  <div className={ styles.heading }>
                    <div className={ styles.num }><strong className="heading-sm color-pink text-center">1</strong></div>
                    <p className="heading-sm color-black"><strong>Know who you want to attract.</strong></p>
                  </div>
                  <div className={ styles.text }>
                    <p className="text-body color-black">When you start a new project, are you aware of the audience you’re creating for? Fact: The best solutions are created for a specific audience. Once you nail down who your people are, you’ll be able to have more impact with your creativity. (Psst… read <a href="https://www.tryinteract.com/blog/creating-customer-personas-for-a-personality-quiz/" target="_blank" rel="noreferrer">How to Create Customer Personas</a> for more tips)</p>
                  </div>
                </div>
                <div className={ styles.item }>
                  <div className={ styles.heading }>
                    <div className={ styles.num }><strong className="heading-sm color-pink text-center">2</strong></div>
                    <p className="heading-sm color-black"><strong>Check out the competition.</strong></p>
                  </div>
                  <div className={ styles.text }>
                    <p className="text-body color-black">You want to make sure your voice is unique. It’s too easy for businesses in the same niche to blend together — be the one to stand out with your content! (Psst... read <a href="https://www.tryinteract.com/blog/use-your-expertise-to-create-the-perfect-quiz-for-your-audience/" target="_blank" rel="noreferrer">How to Utilize Your Expertise</a> for more tips)</p>
                  </div>
                </div>
                <div className={ styles.item }>
                  <div className={ styles.heading }>
                    <div className={ styles.num }><strong className="heading-sm color-pink text-center">3</strong></div>
                    <p className="heading-sm color-black"><strong>Make sure your brand reflects you.</strong></p>
                  </div>
                  <div className={ styles.text }>
                    <p className="text-body color-black">Everything from the messaging you craft to the design you choose should effortlessly represent who you are as a brand. This creates your brand identity, giving your audience even more of a reason to trust and love you. (Psst… read <a href="https://www.tryinteract.com/blog/quiz-design/" target="_blank" rel="noreferrer">Your Complete Guide to Quiz Design</a> for more tips)</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={ { position: `relative`, zIndex: `99` } }>
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-12">
                <h3 className="heading-lg color-black text-center" style={ { marginTop: `8rem` } }>Hold up — let us introduce ourselves...</h3>
              </div>
            </div>
            <div className="row no-gutters justify-content-center align-items-center">
              <div className="col-lg-6">
                <GatsbyImage image={ getImage(data.aboutImage) } alt="Interact team group picture"/>
              </div>
              <div className="col-lg-6">
                <p className="heading-sm color-black" style={ { background: `white`, padding: `3rem 4rem` } }>We’re the small but mighty team behind Interact, the insanely intuitive quiz builder your business friends already love. We help entrepreneurs and creators just like you make personality-infused quizzes to grow their businesses and reach. Want in on the action? Check your email inbox for more info!</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center" style={ { position: `relative`, zIndex: `9` } }>
            <div className="col-lg-12">
              <h3 className="heading-lg color-black text-center" style={ { marginTop: `8rem` } }>Not sure where to start?</h3>
              <p className="heading-sm color-muted-foreground text-center">These resources will show you how to get started with quiz marketing!</p>
            </div>
            <div className="col-lg-8" style={{ marginTop: '3rem' }}>
              <div className={ styles.box }>
                <p className="heading-md color-black"><strong>Check out our Entrepreneur’s Corner!</strong></p>
                <p className="text-body color-muted-foreground text-center">It’s an archive full of Entrepreneurs’ stories just like yours! We are diving deep into overcoming obstacles when growing a business and showing other new Entrepreneurs that you aren’t alone.</p>
                <Button theme="secondary" type="external" href="https://www.tryinteract.com/blog/">Visit Entrepreneur's Corner</Button>
              </div>
              <div className={ styles.box }>
                <p className="heading-md color-black"><strong>Join our free Facebook group!</strong></p>
                <p className="heading-sm color-muted-foreground text-center">You can connect with other Influencers like you, and meet some of the other types as you learn about quizzes, email marketing, and growing your business like whoa. Who knows? You may just find your newest business bestie in there!</p>
                <Button theme="secondary" type="external" href="https://www.facebook.com/groups/interactquizcommunity" style={ { margin: `1rem 0` } }>Join Facebook Group</Button>
              </div>
              <div className={ styles.box }>
                <p className="heading-md color-black"><strong>Follow us on Instagram!</strong></p>
                <p className="heading-sm color-muted-foreground text-center">Folow us to see what we’re up to and get the best tips from successful quiz creators in our community. We’ll see you there!</p>
                <Button theme="secondary" type="external" href="https://www.instagram.com/tryinteract/" style={ { margin: `1rem 0` } }>Follow us on Instagram</Button>
              </div>
            </div>
          </div>
        </Section>
      </main>
      <Footer/>
    </>
  )
}

export default CreatorResultPage

export const query = graphql`
  query {
    aboutImage: file(relativePath: { eq: "about/group.jpg" }) {
      childImageSharp {
        gatsbyImageData (
          width: 558,
          quality: 100,
        )
      }
    }
  }
`